<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import user from "@/models/user.js";
import detailGroupInput from "@/components/detail-group-input.vue";
import loadingVue from "@/components/loading.vue";

const userModel = new user();

/**
 * Starter page
 */
export default {
  components: { Layout, PageHeader, detailGroupInput, loadingVue },
  page: {
    title: "Data User",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      items: [
        {
          text: "Dashboard",
          href: "/",
        },
        {
          text: "User",
          href: "/",
        },
        {
          text: this.$route.name,
          active: true,
        },
      ],
      isLoading: false,
      setData: [],
      columns: [
        {
          value: "name",
          label: "Nama",
        },
        {
          value: "email",
          label: "Email",
          textDefault: true,
        },
        {
          value: "phone_number",
          label: "No.Hp",
        },
        {
          value: "province.name",
          label: "Provinsi",
        },
        {
          value: "regency.name",
          label: "Kab / Kota",
        },
        {
          value: "district.name",
          label: "Kec.",
        },
        {
          value: "village.name",
          label: "Desa",
        },
        {
          value: "created_at",
          label: "Tanggal Dibuat",
        },
        {
          value: "updated_at",
          label: "Tanggal Diperbarui",
        },
      ],
      rowCount: 9,
    };
  },
  watch: {
    "$route.params.id": function(val) {
      if (val !== undefined) {
        this.getDetail();
      }
    },
  },
  mounted() {
    this.getDetail();
  },
  methods: {
    getDetail() {
      this.isLoading = true;
      userModel
        .find(this.$route.params.id)
        .then((resp) => {
          // setModelValue(this.model, resp);
          this.setData = resp;
          this.isLoading = false;
        })
        .catch((error) => {
          console.error(error);
          if (error.response.status == 404) {
            this.$router.push("/404-notfound");
          }
          this.isLoading = false;
        });
    },
    returnBack() {
      // return this.$router.go();
      return this.$router.push("/pengguna");
    },
  },
  middleware: "authentication",
};
</script>
<template>
  <Layout>
    <PageHeader :title="$route.name" :items="items" />
    <b-card>
      <b-card-body>
        <div class="row d-flex justify-content-start m3"></div>
        <b-row v-if="!isLoading">
          <detail-group-input :columns="columns" :data="setData" />
          <b-btn
            variant="success"
            @click="returnBack"
            class="ml-auto text-white"
            style="width: fit-content; display: flex; justify-content: center; align-items: center;"
            ><i class="uil-arrow-left"></i> Kembali
          </b-btn>
        </b-row>
        <b-row v-else>
          <b-col class="my-3" sm="6" md="4" v-for="row in rowCount" :key="row">
            <loadingVue
              class="my-2"
              width="50%"
              height="2rem"
              borderRadius="7px"
            />
            <loadingVue width="100%" height="3rem" borderRadius="7px" />
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
  </Layout>
</template>
