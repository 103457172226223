<script>
export default {
  props: {
    height: String,
    width: String,
    borderRadius: String,
  },
};
</script>
<template>
  <div>
    <div
      :class="`skeleton-loading`"
      :style="{ height, width, borderRadius }"
    ></div>
  </div>
</template>
<style>
.skeleton-loading {
  background: #f0f0f0;
  position: relative;
  overflow: hidden;
}

.skeleton-loading::before {
  content: "";
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    to right,
    transparent,
    rgba(255, 255, 255, 0.8),
    transparent
  );
  animation: loading 1.5s infinite;
}

@keyframes loading {
  100% {
    left: 100%;
  }
}
</style>
