import BaseModel from "@/models/base-model.js";

export default class User extends BaseModel {
  fields = {
    name: {
      type: "text",
      title: "Nama",
      label: "nama",
      minLen: 3,
      validationRules: {
        required: true,
        minLength: true,
      },
    },
    email: {
      type: "text",
      title: "E-mail",
      label: "email",
      validationRules: {
        required: true,
        email: true,
      },
    },
    phone_number: {
      type: "phone",
      title: "No.HP",
      label: "phone_number",
      minLen: 11,
      validationRules: {
        required: true,
        minLength: true,
      },
    },
    gender: {
      type: "radio",
      title: "Jenis Kelamin",
      label: "gender",
      options: {
        pria: "Pria",
        wanita: "Wanita",
      },
      validationRules: {
        required: true,
      },
    },
    password: {
      title: "Password",
      label: "password",
      type: "password",
    },
    role: {
      label: "role",
      title: "Peran",
      type: "default-select",
      options: {
        manager: "Manager",
        kepalaKasir: "Kepala Kasir",
        kasir: "Kasir",
        gudang: "Gudang",
        reseller: "Reseller",
      },
      validationRules: {
        required: true,
      },
    },
  };
  endpoint = process.env.VUE_APP_API_BASE_URL + "users";
}
